<template>
  <div class>
    <div class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div
              class="
                vx-col
                sm:w-full
                md:w-full
                mx-auto
                self-center
                d-theme-dark-bg
              "
            >
              <div class="p-8">
                <div class="vx-card__title mb-4">
                  <h4 class="mb-4">Change Password</h4>
                  <p>Please enter your new password.</p>
                  <div class="mt-2">
                    <vs-alert
                      id="alert"
                      v-if="this.changeError === false"
                      :active.sync="showDismissibleAlert"
                      closable
                      icon-pack="feather"
                      close-icon="icon-x"
                      color="success"
                      >{{ message }}</vs-alert
                    >
                    <vs-alert
                      v-else
                      :active.sync="showDismissibleAlert"
                      closable
                      icon-pack="feather"
                      close-icon="icon-x"
                      color="danger"
                      >{{ message }}</vs-alert
                    >
                  </div>
                </div>
                <form autocomplete="off">
                  <vs-input
                    :success="
                      !errors.first('currentPassword') &&
                      user.currentPassword != ''
                    "
                    val-icon-success="done"
                    val-icon-danger="error"
                    :danger="errors.first('currentPassword') ? true : false"
                    name="currentPassword"
                    :danger-text="errors.first('currentPassword')"
                    label="Current Password"
                    class="w-full mb-5"
                    v-validate="'required'"
                    icon-no-border
                    icon-pack="feather"
                    autocomplete="new-password"
                    v-model="user.currentPassword"
                    :icon="currentPasswordIcon"
                    :type="currentPasswordType"
                    @click.native="showHideCurrentPassword($event)"
                  />

                  <vs-input
                    :success="
                      !errors.first('newPassword') && user.newPassword != ''
                    "
                    val-icon-success="done"
                    val-icon-danger="error"
                    :danger="errors.first('newPassword') ? true : false"
                    name="newPassword"
                    :danger-text="errors.first('newPassword')"
                    label="New Password"
                    class="w-full mb-5"
                    v-validate="{
                      required: true,
                      min: 8,
                      regex:
                        /^.*(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(^[a-zA-Z0-9_.*\W]+$)/,
                    }"
                    ref="password"
                    data-vv-as="new password"
                    icon-no-border
                    icon-pack="feather"
                    autocomplete="new-password"
                    v-model="user.newPassword"
                    :icon="passwordIcon"
                    :type="passwordType"
                    @click.native="showHidePassword($event)"
                  />

                  <vs-input
                    :success="
                      !errors.first('confirmNewPassword') &&
                      user.confirmNewPassword != ''
                    "
                    val-icon-success="done"
                    val-icon-danger="error"
                    :danger="errors.first('confirmNewPassword') ? true : false"
                    name="confirmNewPassword"
                    data-vv-as="confirm password"
                    :danger-text="errors.first('confirmNewPassword')"
                    label="Confirm Password"
                    class="w-full mb-5"
                    v-validate="'required|confirmed:password'"
                    icon-no-border
                    icon-pack="feather"
                    autocomplete="new-password"
                    v-model="user.confirmNewPassword"
                    :icon="confirmPasswordIcon"
                    :type="confirmPasswordType"
                    @click.native="showHideConfirmPassword($event)"
                  />
                </form>

                <div
                  class="
                    flex flex-wrap
                    justify-between
                    flex-col-reverse
                    sm:flex-row
                  "
                >
                  <vs-button
                    class="w-full sm:w-auto"
                    :disabled="!validateForm"
                    @click="changePassword"
                    >Save Changes</vs-button
                  >

                  <vs-button
                    class="w-full sm:w-auto"
                    color="danger"
                    @click="cancelChangePassword"
                    >Cancel</vs-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Validator } from "vee-validate";
const dict = {
  custom: {
    currentPassword: {
      required: "Please enter your current password",
    },
    newPassword: {
      required: "Please enter your password",
      min: "Password must be at least 8 characters",
      regex: "Must have at least one number and  one uppercase letter",
    },
    confirmNewPassword: {
      required: "Please enter your confirm password",
      confirmed: "The confirmation password do not match",
    },
  },
};
Validator.localize("en", dict);
export default {
  data() {
    return {
      user: {
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      },
      showDismissibleAlert: false,
      message: "",
      changeError: false,
      currentPasswordType: "password",
      currentPasswordIcon: "icon icon-eye-off",
      passwordType: "password",
      passwordIcon: "icon icon-eye-off",
      confirmPasswordType: "password",
      confirmPasswordIcon: "icon icon-eye-off",
    };
  },
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.user.currentPassword != "" &&
        this.user.newPassword != ""
      );
    },
  },
  methods: {
    ...mapActions("users", ["changePassword"]),
    showHideCurrentPassword(event) {
      if (
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border" ||
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border"
      ) {
        this.currentPasswordType === "password"
          ? (this.currentPasswordType = "text")
          : (this.currentPasswordType = "password");
        this.currentPasswordIcon === "icon icon-eye-off"
          ? (this.currentPasswordIcon = "icon icon-eye")
          : (this.currentPasswordIcon = "icon icon-eye-off");
      }
      return true;
    },
    showHidePassword(event) {
      if (
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border" ||
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border"
      ) {
        this.passwordType === "password"
          ? (this.passwordType = "text")
          : (this.passwordType = "password");
        this.passwordIcon === "icon icon-eye-off"
          ? (this.passwordIcon = "icon icon-eye")
          : (this.passwordIcon = "icon icon-eye-off");
      }
      return true;
    },
    showHideConfirmPassword(event) {
      if (
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border" ||
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border"
      ) {
        this.confirmPasswordType === "password"
          ? (this.confirmPasswordType = "text")
          : (this.confirmPasswordType = "password");
        this.confirmPasswordIcon === "icon icon-eye-off"
          ? (this.confirmPasswordIcon = "icon icon-eye")
          : (this.confirmPasswordIcon = "icon icon-eye-off");
      }
      return true;
    },
    changePassword() {
      const payload = {
        userDetails: this.user,
      };

      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          this.$store
            .dispatch("auth/changeUserPassword", payload)
            .then((response) => {
              this.changeError = false;
              this.$vs.loading.close();
              const role = localStorage.getItem(
                `${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`
              );
              if (role.toUpperCase() === "org owner".toUpperCase()) {
                this.$router.push({ name: "org-owner-profile" });
              } else {
                if (this.$store.state.AppActiveUser.userType === "superAdmin")
                  this.$router.push("/super-admin/profile");
                else if (
                  this.$store.state.AppActiveUser.userType === "doctorClinic"
                )
                  this.$router.push("/doctor-clinic/profile");
                else
                  this.$router.push(
                    "/" + this.$store.state.AppActiveUser.userType + "/profile"
                  );
              }
            })
            .catch((error) => {
              this.changeError = true;
              this.showDismissibleAlert = true;
              this.message = error.message;
              this.$vs.loading.close();
            });
        }
      });
    },
    cancelChangePassword() {
      const role = localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`
      );
      if (role.toUpperCase() === "org owner".toUpperCase()) {
        this.$router.push({ name: "org-owner-profile" });
      } else {
        if (this.$store.state.AppActiveUser.userType === "superAdmin")
          this.$router.push("/super-admin/profile");
        else if (this.$store.state.AppActiveUser.userType === "doctorClinic")
          this.$router.push("/doctor-clinic/profile");
        else
          this.$router.push(
            "/" + this.$store.state.AppActiveUser.userType + "/profile"
          );
      }
    },
  },
};
</script>
